import {db} from "../firebase"

class CostumeService {
    async getAll() {
        const snapshot = await db.collection("costumeCompanies").get()
        const documents = [];
        snapshot.forEach(doc => {
            let data = doc.data();
            if (data.hasOwnProperty('name')) {
                data.companyName = data.name;
                delete data.name;
            }
            const document = { id: doc.id, ...data };
            documents.push(document);
        })
        return documents.sort((a, b) => a.companyName.toLowerCase().localeCompare(b.companyName.toLowerCase()));
    }

    async get(id){
        const costume = await db.collection("costumeCompanies").doc(id).get()
        return costume.data()
    }

    create(costume) {
        db.collection("costumeCompanies").add(costume)
            .then((docRef) => {
                console.log(`Document written with ID: ${docRef.id}`)
            })
            .catch((error) => {
                console.error(`Error adding document: ${error}`)
            })
    }

    update(id, value) {
        db.collection("costumeCompanies").doc(id).update(value)
            .then(docRef => {
                console.log(`Document updated: ${docRef}`)
            })
            .catch((error) => {
                console.error(`Error deleting document: ${error}`)
            })
    }

    delete(id) {
        db.collection("costumeCompanies").doc(id).delete()
            .then(docRef => {
                console.log(`Document deleted: ${docRef}`)
            })
            .catch((error) => {
                console.error(`Error deleting document: ${error}`)
            })
    }
}

export default new CostumeService();