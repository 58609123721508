<template>
  <div class="about">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-2">Folk Costume Manufacturers</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <table class="table">
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Regions Specialized</th>
            <th>Main Contact</th>
            <th id="phoneNumber">Phone Number</th>
            <th>Email</th>
            <th>Facebook</th>
            <th>Website</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="costumeCompany in costumeCompanies" :key="costumeCompany.id">
            <td>{{ costumeCompany.companyName || costumeCompany.name }}</td>
            <td>{{ costumeCompany.regionsSpecialized }}</td>
            <td>{{ costumeCompany.mainContact }}</td>
            <td>{{ costumeCompany.phoneNumber }}</td>
            <td>{{ costumeCompany.email }}</td>
            <td>
              <a target="_blank" rel="noopener noreferrer" :href="costumeCompany.facebook" v-if="costumeCompany.facebook">
                <img src="../img/fb.png"/>
              </a>
            </td>
            <td>
              <a target="_blank" rel="noopener noreferrer" :href="costumeCompany.website" v-if="costumeCompany.website">
                <img src="../img/web.png" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import CostumeService from "../services/costumeService";

export default {
  name: "Costumes",
  data() {
    return {
      isLoading: false,
      costumeCompanies: []
    };
  },
  async mounted() {
    this.costumeCompanies = await CostumeService.getAll();
  }
};
</script>
<style lang="scss" scoped>
.org-description {
  margin-top: 50px;
}
.hero-body {
  background: #ed213a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #93291e,
    #ed213a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
img {
  max-height: 25px;
}
table {
  margin-top: 40px;
}
#phoneNumber {
  min-width: 115px;
}
</style>